<!--
 * =====================================================================
 * 작 업 명  : 메뉴 갱신
 * 파 일 명  : MenuReload.vue
 * 변경이력
 * 2022-06-17 / 정태화 / 최초작성
 * 2022-10-25 / 정태화 / 권한그룹별 메뉴권한 바인딩하는 예제 추가
  * =====================================================================
 -->
 <template>
    <div>
        <br><br>
        사이트 메뉴권한 메모리 초기화 : <button @click="menuMemoryInit()">초기화</button>
        <br><br>
        학생 여부 : {{IS_STUDENT}} <button @click="setMenuRole('STUDENT')" >메뉴권한 반영</button>
        <br><br>
        선생님 여부 : {{IS_TEACHER}} <button @click="setMenuRole('TEACHER')" >메뉴권한 반영</button>
        <br><br>
        학교관리자 여부 : {{IS_ADMIN}} <button @click="setMenuRole('ADMIN')" >메뉴권한 반영</button>
        <br><br>
        닷관리자 여부 : {{IS_DOTADMIN}} <button @click="setMenuRole('DOT-ADMIN')" >메뉴권한 반영</button>
    </div>
</template>
<script>
    import { $axios, $session } from "dot-framework";
    import { router } from "@/routes/index.js";
    import store from "@/store/index.js";
    import global from "@/js/global.js";

    export default
    {
        data() {
            return {
                ACCESS_TOKEN: "",
                IS_STUDENT: "",
                IS_TEACHER: "",
                IS_ADMIN: "",
                IS_DOTADMIN: "",
            }
        },
        methods:
        {
            // 메뉴 메모리 초기화
            menuMemoryInit()
            {
                let siteNo = process.env.VUE_APP_SITE_NO;
                console.log(siteNo)
                let url = "/site-app/v1/sites/" + siteNo + "/memory/init";
                $axios.delete(url).then((response) => {
                    if (response.status == 204) {
                        alert("사용자 권한그룹/메뉴를 성공적으로 갱신했습니다.");
                        router.push({"path": "/test"});
                    }
                });
            },
            // 권한그룹에 의해 메뉴권한 재설정
            setMenuRole(authGroupId)
            {
                store.dispatch("setMenuList", authGroupId);
            }
        },
        mounted()
        {
            this.ACCESS_TOKEN = $session.getAccessToken();
            this.IS_STUDENT = global.isStudent();
            this.IS_TEACHER = global.isTeacher();
            this.IS_ADMIN = global.isAdmin();
            this.IS_DOTADMIN = global.isDotAdmin();
        },
    }
</script>
<style>
</style>
